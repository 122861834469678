import { LayoutWidgetConfig } from './config.type';
import {
  BaseCssConfig,
  BaseWidgetProperties,
  ComponentLabel,
  GridPosition,
  SupportedDevice,
  ValidationRules,
  WidgetFields,
} from './workflow.type';
import { WorkflowPageStateConfig, ZoneConfiguration } from './workflow.v2.type';

export function defaultCssProperties(): BaseCssConfig {
  return {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginUnit: 'px',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingUnit: 'px',
    selectedOpacity: 100,
    isTextBold: false,
    isiTextItalic: false,
    isTextUnderline: false,
    shadowBlur: 0,
    shadowSpread: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: '',
    alertColor: '',
  };
}

export function defaultGridPosition(): GridPosition {
  return {
    x: 0,
    y: 0,
    rows: 2,
    cols: 15,
    dragEnabled: false,
    resizeEnabled: false,
  };
}

export type UiZoneWidgetConfig = {
  name: ComponentLabel;
  uniqueId: string | number;
  gridPosition: GridPosition;
  attributes: UiZoneWidgetAttributeConfig;
  nestedWidgets?: {
    [uniqueId: string]: UiZoneWidgetAttributeConfig;
  };
  startHooks?: WidgetHooks[];
  endHooks?: WidgetHooks[];
};

export enum WidgetHooks {
  EXCHANGE_RESET_ACCOUNTS = 'exchange-reset-accounts',
}

export type UiZoneWidgetAttributeConfig = {
  css_config: BaseCssConfig;
  elementsConfig: WidgetFields[];
  hideCondition?: string;
  widgetProperties: BaseWidgetProperties;
  validationRules?: ValidationRules[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export type PageHooks = {
  name: string;
  enabled: boolean;
};

export type UiPageConfig = {
  url: string;
  mainComponentName: string;
  pageTypeConfig: 'new';
  widgetLayouts: UiZoneWidgetConfig[];
  title: string;
  /// Should remove but needed for workflow ui for now as dynamic grid component looks for it to use in
  // injector creation
  widgetSections: WorkflowPageStateConfig[];
  pageType: 'dynamicGrid' | 'staticGrid';
  pageConfiguration?: {
    labelsAsPlaceholder?: boolean;
    fontsConfig?: {
      [key: string]: { [key: string]: string };
      h1: { 'font-family': string; 'font-weight': string };
      h2: { 'font-family': string; 'font-weight': string };
      h3: { 'font-family': string; 'font-weight': string };
      bodyFont: { 'font-family': string; 'font-weight': string };
    };
    gridsterMargin: {
      left: number;
      right: number;
      top: number;
      unit: string;
    };
    backgroundColor?: string;
    backgroundSize?: string;
    backgroundGradient?: string;
    backgroundImage?: {
      image: string;
      opacity: string;
    };
    showHeader: boolean;
    borderConfig?: {
      width: string;
      unit: string;
      color: string;
      borderRadius: {
        [key: string]: string;
        topLeft: string;
        topRight: string;
        bottomLeft: string;
        bottomRight: string;
        unit: string;
      };
    };
    backgroundPosition?: {
      x: string;
      y: string;
    };
    isCustomGridActive?: boolean;
    isBodyScrollingEnabled?: boolean;
    autoRedirectConfig?: {
      isAutoRedirectEnabled?: boolean;
      autoRedirectTimer?: number;
      autoRedirectPersona?: string;
      autoRedirectDestinationZone?: string;
      autoRedirectDestinationPageIndex?: number;
      autoRedirectDestinationPageUrl?: string;
    };
    pageHooks?: PageHooks[];
  };
};

export type UiZoneConfig = {
  zoneConfiguration: ZoneConfiguration;
  pages: UiPageConfig[];
};

// Utility used to tell difference between UiZoneDevicesConfig and Default in CanvasSubscription
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isUiZoneConfig(config: any): config is UiZoneConfig {
  return (<UiZoneConfig>config)?.pages !== undefined;
}
// Utility used to tell difference between UiZoneDevicesConfig and Default in CanvasSubscription
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isUiPageConfig(config: any): config is UiPageConfig {
  return (<UiPageConfig>config)?.pageTypeConfig !== undefined;
}

export function isUiZoneWidgetConfig(
  config: UiZoneWidgetConfig | LayoutWidgetConfig,
): config is UiZoneWidgetConfig {
  return (<LayoutWidgetConfig>config)?.position === undefined;
}

export type UiZoneDevicesConfig = {
  [key in SupportedDevice]: UiZoneConfig;
};

export type UiZoneSubscriptionsConfig = {
  [key: string]: UiZoneDevicesConfig;
};

export type UiZoneRolesConfig = {
  [key: string]: UiZoneSubscriptionsConfig;
};
