/* eslint-disable prettier/prettier */
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { InputPriceDirective } from '@app/finxone-web-frontend/app/lib/directives/input-format-price.directive';
import { OnBlurValidationDirective } from '@app/finxone-web-frontend/app/lib/directives/on-blur-validation.directive';
import { RgbaToHexPipe } from '@finxone-platform/shared/pipes';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuillModule } from 'ngx-quill';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TreeSelectModule } from 'primeng/treeselect';
import { AlertErrorMessageComponent } from './alert-error-message/alert-error-message.component';
import { AlertMessageComponent } from './alert-message-component/alert-message.component';
import { AlertSuccessMessageComponent } from './alert-success-message/alert-success-message.component';
import { AlertWarningMessageComponent } from './alert-warning-message/alert-warning-message.component';
import { BottomPopupComponent } from './bottom-popup/bottom-popup.component';
import { NumberFormatDirective } from './directive/addcomma.directive';
import { ClickOutsideDirective } from './directive/clickoutside.directive';
import { SafeHtmlPipe } from './directive/safeHtml.pipe';
import { FinxButtonComponent } from './finx-button/finx-button.component';
import { FinxDatePickerComponent } from './finx-date-picker/finx-date-picker.component';
import { FinxDropdownComponent } from './finx-dropdown/finx-dropdown.component';
import { FinxInputComponent } from './finx-input/finx-input.component';
import { FinxInputMaskComponent } from './finx-inputmask/finx-inputmask.component';
import { FinxLabelComponent } from './finx-label/finx-label.component';
import { FinxMultiselectDropdownComponent } from './finx-multi-select-dropdown/finx-multi-select-dropdown.component';
import { FinxSwitchComponent } from './finx-switch/finx-switch.component';
import { FinxTableComponent } from './finx-table/finx-table.component';
import { FinxTimePickerComponent } from './finx-time-picker/finx-time-picker.component';
import { ProgressBarComponent } from './progress-bar-module/progress-bar.component';
import { WorkflowFinxInputComponent } from './workflow-finx-input/workflow-finx-input.component';
import { WorkflowFinxTableComponent } from './workflow-finx-table/worfklow-finx-table.component';
import { WorkflowCardSelectionDialogComponent } from './workflow/card-selection-dialog/card-selection-dialog.component';
import { WorkflowResetWidgetDialogComponent } from './workflow/reset-widget-config-rhs/reset-widget-config-rhs-dialog.component';
import { WorkflowFinxColorpickerComponent } from './workflow/workflow-finx-colorpicker/workflow-finx-colorpicker.component';
import { WorkflowFinxDropdownComponent } from './workflow/workflow-finx-dropdown/workflow-finx-dropdown.component';
import { WorkflowFinxMultiselectComponent } from './workflow/workflow-finx-multiselect/workflow-finx-multiselect.component';
import { WorkflowFinxSwitchComponent } from './workflow/workflow-finx-switch/workflow-finx-switch.component';
import { WorkflowFinxTreeselectComponent } from './workflow/workflow-finx-treeselect/workflow-finx-treeselect.component';
import { WorkflowTextEditorDialogComponent } from './workflow/workflow-text-editor-dialog/finx-button/workflow-text-editor-dialog.component';
//TODO Split out workflow ui components into their own module
@NgModule({
  declarations: [
    FinxInputComponent,
    FinxInputMaskComponent,
    FinxLabelComponent,
    FinxButtonComponent,
    FinxDatePickerComponent,
    FinxDropdownComponent,
    WorkflowTextEditorDialogComponent,
    WorkflowCardSelectionDialogComponent,
    WorkflowResetWidgetDialogComponent,
    WorkflowFinxInputComponent,
    BottomPopupComponent,
    ClickOutsideDirective,
    AlertErrorMessageComponent,
    AlertSuccessMessageComponent,
    WorkflowFinxDropdownComponent,
    WorkflowFinxSwitchComponent,
    FinxSwitchComponent,
    WorkflowFinxTreeselectComponent,
    WorkflowFinxColorpickerComponent,
    WorkflowFinxMultiselectComponent,
    ProgressBarComponent,
    FinxTimePickerComponent,
    NumberFormatDirective,
    FinxMultiselectDropdownComponent,
    FinxTableComponent,
    SafeHtmlPipe,
    RgbaToHexPipe,
    WorkflowFinxTableComponent,
    AlertWarningMessageComponent,
    InputPriceDirective,
    AlertMessageComponent,
    OnBlurValidationDirective,
  ],
  imports: [
    DropdownModule,
    TreeSelectModule,
    ProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    // InputMaskModule,
    CalendarModule,
    QuillModule,
    ColorPickerModule,
    CommonModule,
    NgxSmartModalModule.forRoot(),
    MatIconModule,
    MultiSelectModule,
    TableModule,
  ],
  providers: [NgxSmartModalService, DecimalPipe, SafeHtmlPipe],
  exports: [
    FinxInputComponent,
    FinxInputMaskComponent,
    FinxLabelComponent,
    FinxButtonComponent,
    FinxDatePickerComponent,
    FinxDropdownComponent,
    WorkflowTextEditorDialogComponent,
    WorkflowCardSelectionDialogComponent,
    WorkflowResetWidgetDialogComponent,
    WorkflowFinxInputComponent,
    BottomPopupComponent,
    ClickOutsideDirective,
    SafeHtmlPipe,
    AlertErrorMessageComponent,
    AlertSuccessMessageComponent,
    WorkflowFinxDropdownComponent,
    WorkflowFinxSwitchComponent,
    FinxSwitchComponent,
    WorkflowFinxTreeselectComponent,
    WorkflowFinxColorpickerComponent,
    WorkflowFinxMultiselectComponent,
    ProgressBarComponent,
    FinxTimePickerComponent,
    FinxMultiselectDropdownComponent,
    FinxTableComponent,
    ColorPickerModule,
    WorkflowFinxTableComponent,
    AlertWarningMessageComponent,
    InputPriceDirective,
    AlertMessageComponent,
    OnBlurValidationDirective,
  ],
})
export class UiComponentsModule {}
